import {
  Component,
  computed,
  Directive,
  inject,
  input,
  Signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ListboxModule } from 'primeng/listbox';
import { SkeletonModule } from 'primeng/skeleton';
import { Property } from '../../../rc-api/generated';

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoopValueAccessorDirective,
    },
  ],
})
export class NoopValueAccessorDirective implements ControlValueAccessor {
  public writeValue(_obj: unknown): void {}
  public registerOnChange(_fn: unknown): void {}
  public registerOnTouched(_fn: unknown): void {}
}

export function injectNgControl() {
  const ngControl = inject(NgControl, { self: true, optional: true });

  if (
    ngControl instanceof FormControlDirective ||
    ngControl instanceof FormControlName ||
    ngControl instanceof NgModel
  ) {
    return ngControl;
  }

  throw new Error('Unable to find ngControl, did you forget formControlName?');
}

export type UnitSelectionOptions = Array<{
  label: string;
  items: Array<{ label: string; value: string; active: boolean }>;
}>;

/**
 * Reusable form control allowing single or multiple selection of individual PropertyUnits
 */
@Component({
  selector: 'rc-ui-property-unit-select',
  templateUrl: './property-unit-select.component.html',
  styleUrl: './property-unit-select.component.scss',
  hostDirectives: [NoopValueAccessorDirective],
  imports: [ListboxModule, SkeletonModule, ReactiveFormsModule, RouterLink],
})
export class RcUiPropertyUnitSelectComponent {
  public ngControl = injectNgControl();

  /**
   * List of properties and their units to display
   */
  public properties = input.required<Array<Property> | undefined>();

  /**
   * If true, the user can select multiple units
   */
  public multiselect = input<boolean>(false);

  /**
   * If true, only active units will be displayed
   */
  public activeOnly = input<boolean>(true);

  public groupedUnitsSelectionOptions: Signal<
    UnitSelectionOptions | undefined
  > = computed(() => {
    const properties = this.properties();
    if (!properties) {
      return undefined;
    }
    return properties
      .map((property) => {
        if (property.units?.length === 1) {
          // Single Unit property
          const unit = {
            label: property.address,
            value: property.units[0].id,
            active: property.units[0].active,
          };

          return {
            label: '',
            items: this.activeOnly() && !unit.active ? [] : [unit],
          };
        } else {
          // Multi Unit property
          let items = (property.units || []).map((unit) => {
            return {
              label: '&nbsp;&nbsp;&nbsp;&nbsp;' + unit.name,
              value: unit.id,
              active: unit.active,
            };
          });

          // Filter out inactive units when activeOnly is true
          if (this.activeOnly()) {
            items = items.filter((item) => item.active);
          }

          return {
            label: property.address,
            items,
          };
        }
      })
      .filter((group) => group.items.length > 0);
  });
}
