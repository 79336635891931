@if (groupedUnitsSelectionOptions(); as units) {
  @if (units.length > 0) {
    <p-listbox
      [options]="units"
      [group]="true"
      [multiple]="multiselect()"
      [metaKeySelection]="false"
      [listStyle]="{ 'max-height': '20rem' }"
      [formControl]="ngControl.control"
    >
      <ng-template
        let-group
        pTemplate="group"
      >
        @if (group.label) {
          <div class="flex align-items-center">
            <span>{{ group.label }}</span>
          </div>
        }
      </ng-template>
      <ng-template
        let-unit
        pTemplate="item"
      >
        <span [innerHTML]="unit.label"></span>
      </ng-template>
    </p-listbox>
  } @else {
    <p>
      No units available, manage them on the
      <a routerLink="/manager/properties">properties page</a>
    </p>
  }
} @else {
  <p-skeleton height="4rem"></p-skeleton>
}
